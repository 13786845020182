<template>
  <div v-if="userInfo != null">
    <van-list v-model="loading" :finished="finished" finished-text="已经到底了" @load="onPageChange">
      <van-cell v-for="item in dataList" :key="item.id">
        <template #title>
          <span class="font-color-gray">{{item.create_time}}</span>
        </template>
        <template #right-icon>
          <span class="font-color-green">+{{item.money}}</span>
        </template>
      </van-cell>
    </van-list>
    <global :hdid="hdid"/>
  </div>
</template>

<script>
  
  import {GetCash} from "./services/services";
  import global from './components/global'
  import {mapGetters} from "vuex";
  export default {
    name:'cash',
    components: {global},
    data() {
      return {
        hdid:undefined,
        dataList:[],
        pagination:{
          pageIndex:1,
          pageSize:10,
          pageCount:0,
          total:0
        },
        loading:false,
        finished:false
      }
    },
    computed:{
      ...mapGetters(['snapshotuser','userInfo']),
    },
    created(){
      this.hdid=parseInt(this.$route.params.hdid)
    },
    mounted() {

      this.findList()
    },
    methods: {
      findList(star){
        this.loading = true
        let params = {
          searchbean:{
            hdid:this.hdid
          },
          pageIndex:this.pagination.pageIndex,
          pageSize:this.pagination.pageSize
        }
        star=star==undefined?true:star
        GetCash(params).then(result => {
          this.loading=false
          if(result.data.code==1){
            const {list,recordCount,pageCount} = result.data.data
            this.dataList = star?list:[...this.dataList,...list]
            this.pagination.total = recordCount
            this.pagination.pageCount=pageCount
            if(this.dataList.length>=recordCount){
              this.finished = true
            }
          }else{
            this.$toast(result.data.msg)
          }
        })
      },
      onPageChange() {
        if (this.pagination.pageIndex >= this.pagination.pageCount) {
          return false
        }
        this.pagination.pageIndex += 1
        this.findList(false)
      },
    }
  }
</script>
<style lang="less" scoped>

</style>
